import React from 'react';
import { GFLCharacter } from '../components/exilium-character';

interface IProps {
  name: string;
}

export const GFLProfileTeams: React.FC<IProps> = ({ name }) => {
  return (
    <>
      <p>
        Use the section below to create a team for this character, by picking a
        number of teammates from each 'group'.
      </p>
      {name === 'qiongjiu' && (
        <>
          <h6>DPS/Amplifier (pick 2 characters):</h6>
          <ul>
            <li>
              <GFLCharacter
                slug="tololo"
                mode="inline"
                enablePopover
                showLabel
              />
            </li>
            <li>
              <GFLCharacter
                slug="ullrid"
                mode="inline"
                enablePopover
                showLabel
              />
            </li>
            <li>
              <GFLCharacter
                slug="mosin-nagant"
                mode="inline"
                enablePopover
                showLabel
              />
            </li>
            <li>
              <GFLCharacter
                slug="sharkry"
                mode="inline"
                enablePopover
                showLabel
              />{' '}
              - Best if you have V3 Qiongjiu
            </li>
            <li>
              <GFLCharacter
                slug="nemesis"
                mode="inline"
                enablePopover
                showLabel
              />
            </li>
            <li>
              <GFLCharacter
                slug="sabrina"
                mode="inline"
                enablePopover
                showLabel
              />{' '}
              - When you can perform Counters on enemies.
            </li>
            <li>
              <GFLCharacter
                slug="groza"
                mode="inline"
                enablePopover
                showLabel
              />{' '}
              - When you can perform Counters on enemies.
            </li>
          </ul>
          <h6>Sustain (pick 1 character):</h6>
          <ul>
            <li>
              <GFLCharacter
                slug="suomi"
                mode="inline"
                enablePopover
                showLabel
              />
            </li>
            <li>
              <GFLCharacter
                slug="ksenia"
                mode="inline"
                enablePopover
                showLabel
              />
            </li>
            <li>
              <GFLCharacter
                slug="cheeta"
                mode="inline"
                enablePopover
                showLabel
              />
            </li>
            <li>
              <GFLCharacter
                slug="colphne"
                mode="inline"
                enablePopover
                showLabel
              />
            </li>
          </ul>
        </>
      )}
      {name === 'tololo' && (
        <>
          <h6>DPS/Amplifier (pick 1 character):</h6>
          <ul>
            <li>
              <GFLCharacter
                slug="qiongjiu"
                mode="inline"
                enablePopover
                showLabel
              />{' '}
              - Best option
            </li>
            <li>
              <GFLCharacter
                slug="nagant"
                mode="inline"
                enablePopover
                showLabel
              />
            </li>
            <li>
              <GFLCharacter
                slug="sabrina"
                mode="inline"
                enablePopover
                showLabel
              />{' '}
              - situational
            </li>
          </ul>
          <h6>Sustain/Tank (pick 1 character):</h6>
          <ul>
            <li>
              <GFLCharacter
                slug="sabrina"
                mode="inline"
                enablePopover
                showLabel
              />
            </li>
            <li>
              <GFLCharacter
                slug="suomi"
                mode="inline"
                enablePopover
                showLabel
              />
            </li>
            <li>
              <GFLCharacter
                slug="cheeta"
                mode="inline"
                enablePopover
                showLabel
              />
            </li>
            <li>
              <GFLCharacter
                slug="ksenia"
                mode="inline"
                enablePopover
                showLabel
              />
            </li>
            <li>
              <GFLCharacter
                slug="groza"
                mode="inline"
                enablePopover
                showLabel
              />
            </li>
            <li>
              <GFLCharacter
                slug="colphne"
                mode="inline"
                enablePopover
                showLabel
              />
            </li>
          </ul>
          <h6>DPS (pick 1 character):</h6>
          <ul>
            <li>
              <GFLCharacter
                slug="sharkry"
                mode="inline"
                enablePopover
                showLabel
              />
            </li>
            <li>
              <GFLCharacter
                slug="ullrid"
                mode="inline"
                enablePopover
                showLabel
              />
            </li>
            <li>
              <GFLCharacter
                slug="mosin-nagant"
                mode="inline"
                enablePopover
                showLabel
              />
            </li>
            <li>
              You can use any second Sustain here depending on the content.
            </li>
          </ul>
        </>
      )}
      {name === 'mosin-nagant' && (
        <>
          <h6>DPS/Amplifier (pick 1 character):</h6>
          <ul>
            <li>
              <GFLCharacter
                slug="qiongjiu"
                mode="inline"
                enablePopover
                showLabel
              />{' '}
              - Best option
            </li>
            <li>
              <GFLCharacter
                slug="nagant"
                mode="inline"
                enablePopover
                showLabel
              />
            </li>
            <li>
              <GFLCharacter
                slug="sabrina"
                mode="inline"
                enablePopover
                showLabel
              />{' '}
              - situational
            </li>
          </ul>
          <h6>Sustain/Tank (pick 1 character):</h6>
          <ul>
            <li>
              <GFLCharacter
                slug="sabrina"
                mode="inline"
                enablePopover
                showLabel
              />
            </li>
            <li>
              <GFLCharacter
                slug="suomi"
                mode="inline"
                enablePopover
                showLabel
              />
            </li>
            <li>
              <GFLCharacter
                slug="cheeta"
                mode="inline"
                enablePopover
                showLabel
              />
            </li>
            <li>
              <GFLCharacter
                slug="ksenia"
                mode="inline"
                enablePopover
                showLabel
              />
            </li>
            <li>
              <GFLCharacter
                slug="groza"
                mode="inline"
                enablePopover
                showLabel
              />
            </li>
            <li>
              <GFLCharacter
                slug="colphne"
                mode="inline"
                enablePopover
                showLabel
              />
            </li>
          </ul>
          <h6>DPS (pick 1 character):</h6>
          <ul>
            <li>
              <GFLCharacter
                slug="sharkry"
                mode="inline"
                enablePopover
                showLabel
              />
            </li>
            <li>
              <GFLCharacter
                slug="ullrid"
                mode="inline"
                enablePopover
                showLabel
              />
            </li>
            <li>
              <GFLCharacter
                slug="tololo"
                mode="inline"
                enablePopover
                showLabel
              />
            </li>
            <li>
              You can use any second Sustain here depending on the content.
            </li>
          </ul>
        </>
      )}
      {name === 'sabrina' && (
        <>
          <h6>DPS/Amplifier (pick 1 character):</h6>
          <ul>
            <li>
              <GFLCharacter
                slug="qiongjiu"
                mode="inline"
                enablePopover
                showLabel
              />{' '}
              - Best option
            </li>
            <li>
              <GFLCharacter
                slug="nagant"
                mode="inline"
                enablePopover
                showLabel
              />
            </li>
            <li>
              <GFLCharacter
                slug="cheeta"
                mode="inline"
                enablePopover
                showLabel
              />
            </li>
          </ul>
          <h6>Sustain/DPS (pick 1 character):</h6>
          <ul>
            <li>
              <GFLCharacter
                slug="colphne"
                mode="inline"
                enablePopover
                showLabel
              />
            </li>
            <li>
              <GFLCharacter
                slug="cheeta"
                mode="inline"
                enablePopover
                showLabel
              />
            </li>
            <li>
              <GFLCharacter
                slug="ksenia"
                mode="inline"
                enablePopover
                showLabel
              />
            </li>
            <li>
              <GFLCharacter
                slug="suomi"
                mode="inline"
                enablePopover
                showLabel
              />
            </li>
            <li>
              <GFLCharacter
                slug="sharkry"
                mode="inline"
                enablePopover
                showLabel
              />
            </li>
            <li>
              <GFLCharacter
                slug="ullrid"
                mode="inline"
                enablePopover
                showLabel
              />
            </li>
            <li>
              <GFLCharacter
                slug="mosin-nagant"
                mode="inline"
                enablePopover
                showLabel
              />
            </li>
            <li>
              <GFLCharacter
                slug="tololo"
                mode="inline"
                enablePopover
                showLabel
              />
            </li>
          </ul>
          <h6>DPS (pick 1 character):</h6>
          <ul>
            <li>
              <GFLCharacter
                slug="sharkry"
                mode="inline"
                enablePopover
                showLabel
              />
            </li>
            <li>
              <GFLCharacter
                slug="ullrid"
                mode="inline"
                enablePopover
                showLabel
              />
            </li>
            <li>
              <GFLCharacter
                slug="mosin-nagant"
                mode="inline"
                enablePopover
                showLabel
              />
            </li>
            <li>
              <GFLCharacter
                slug="tololo"
                mode="inline"
                enablePopover
                showLabel
              />
            </li>
            <li>
              You can use any second Sustain here depending on the content.
            </li>
          </ul>
        </>
      )}
      {name === 'peritya' && (
        <>
          <h6>AoE DPS (pick 1 character):</h6>
          <ul>
            <li>
              <GFLCharacter
                slug="lotta"
                mode="inline"
                enablePopover
                showLabel
              />
            </li>
            <li>
              <GFLCharacter
                slug="littara"
                mode="inline"
                enablePopover
                showLabel
              />
            </li>
            <li>
              <GFLCharacter
                slug="vepley"
                mode="inline"
                enablePopover
                showLabel
              />
            </li>
          </ul>
          <h6>Tank/AoE DPS (pick 1 character):</h6>
          <ul>
            <li>
              <GFLCharacter
                slug="sabrina"
                mode="inline"
                enablePopover
                showLabel
              />
            </li>
            <li>
              <GFLCharacter
                slug="groza"
                mode="inline"
                enablePopover
                showLabel
              />
            </li>
            <li>
              <GFLCharacter
                slug="lotta"
                mode="inline"
                enablePopover
                showLabel
              />
            </li>
            <li>
              <GFLCharacter
                slug="littara"
                mode="inline"
                enablePopover
                showLabel
              />
            </li>
            <li>
              <GFLCharacter
                slug="vepley"
                mode="inline"
                enablePopover
                showLabel
              />
            </li>
          </ul>
          <h6>Sustain/Tank (pick 1 character):</h6>
          <ul>
            <li>
              <GFLCharacter
                slug="colphne"
                mode="inline"
                enablePopover
                showLabel
              />
            </li>
            <li>
              <GFLCharacter
                slug="cheeta"
                mode="inline"
                enablePopover
                showLabel
              />
            </li>
            <li>
              <GFLCharacter
                slug="ksenia"
                mode="inline"
                enablePopover
                showLabel
              />
            </li>
            <li>
              <GFLCharacter
                slug="suomi"
                mode="inline"
                enablePopover
                showLabel
              />
            </li>
            <li>
              <GFLCharacter
                slug="sabrina"
                mode="inline"
                enablePopover
                showLabel
              />
            </li>
            <li>
              <GFLCharacter
                slug="groza"
                mode="inline"
                enablePopover
                showLabel
              />
            </li>
          </ul>
        </>
      )}
      {name === 'suomi' && (
        <>
          <p>
            <strong>
              Suomi is so good she can be paired with anyone and in any team.
            </strong>
          </p>
        </>
      )}
      {name === 'cheeta' && (
        <>
          <p>
            <strong>
              Cheeta can be slotted in any team, but she prefers those who
              perform targeted attacks.
            </strong>
          </p>
        </>
      )}
      {name === 'ksenia' && (
        <>
          <p>
            <strong>
              Ksenia can be slotted in any team, but she prefers those who
              perform targeted attacks.
            </strong>
          </p>
        </>
      )}
      {name === 'colphne' && (
        <>
          <p>
            <strong>
              Colphne can be slotted in any team where you need a Healer.
            </strong>
          </p>
        </>
      )}
      {name === 'vepley' && (
        <>
          <h6>DPS/Amplifier (pick 2 characters):</h6>
          <ul>
            <li>
              <GFLCharacter
                slug="tololo"
                mode="inline"
                enablePopover
                showLabel
              />
            </li>
            <li>
              <GFLCharacter
                slug="ullrid"
                mode="inline"
                enablePopover
                showLabel
              />
            </li>
            <li>
              <GFLCharacter
                slug="mosin-nagant"
                mode="inline"
                enablePopover
                showLabel
              />
            </li>
            <li>
              <GFLCharacter
                slug="nagant"
                mode="inline"
                enablePopover
                showLabel
              />
            </li>
            <li>
              <GFLCharacter
                slug="nemesis"
                mode="inline"
                enablePopover
                showLabel
              />
            </li>
            <li>
              <GFLCharacter
                slug="sabrina"
                mode="inline"
                enablePopover
                showLabel
              />{' '}
              - When you can perform Counters on enemies.
            </li>
            <li>
              <GFLCharacter
                slug="groza"
                mode="inline"
                enablePopover
                showLabel
              />{' '}
              - When you can perform Counters on enemies.
            </li>
          </ul>
          <h6>Sustain (pick 1 character):</h6>
          <ul>
            <li>
              <GFLCharacter
                slug="suomi"
                mode="inline"
                enablePopover
                showLabel
              />
            </li>
            <li>
              <GFLCharacter
                slug="ksenia"
                mode="inline"
                enablePopover
                showLabel
              />
            </li>
            <li>
              <GFLCharacter
                slug="colphne"
                mode="inline"
                enablePopover
                showLabel
              />
            </li>
            <li>
              <GFLCharacter
                slug="cheeta"
                mode="inline"
                enablePopover
                showLabel
              />
            </li>
          </ul>
        </>
      )}
      {name === 'ullrid' && (
        <>
          <h6>DPS/Amplifier (pick 1 character):</h6>
          <ul>
            <li>
              <GFLCharacter
                slug="qiongjiu"
                mode="inline"
                enablePopover
                showLabel
              />{' '}
              - Best option
            </li>
            <li>
              <GFLCharacter
                slug="nagant"
                mode="inline"
                enablePopover
                showLabel
              />
            </li>
            <li>
              <GFLCharacter
                slug="sabrina"
                mode="inline"
                enablePopover
                showLabel
              />{' '}
              - situational
            </li>
          </ul>
          <h6>Sustain/Tank (pick 1 character):</h6>
          <ul>
            <li>
              <GFLCharacter
                slug="sabrina"
                mode="inline"
                enablePopover
                showLabel
              />
            </li>
            <li>
              <GFLCharacter
                slug="suomi"
                mode="inline"
                enablePopover
                showLabel
              />
            </li>
            <li>
              <GFLCharacter
                slug="cheeta"
                mode="inline"
                enablePopover
                showLabel
              />
            </li>
            <li>
              <GFLCharacter
                slug="ksenia"
                mode="inline"
                enablePopover
                showLabel
              />
            </li>
            <li>
              <GFLCharacter
                slug="groza"
                mode="inline"
                enablePopover
                showLabel
              />
            </li>
            <li>
              <GFLCharacter
                slug="colphne"
                mode="inline"
                enablePopover
                showLabel
              />
            </li>
          </ul>
          <h6>DPS (pick 1 character):</h6>
          <ul>
            <li>
              <GFLCharacter
                slug="sharkry"
                mode="inline"
                enablePopover
                showLabel
              />
            </li>
            <li>
              <GFLCharacter
                slug="mosin-nagant"
                mode="inline"
                enablePopover
                showLabel
              />
            </li>
            <li>
              <GFLCharacter
                slug="tololo"
                mode="inline"
                enablePopover
                showLabel
              />
            </li>
            <li>
              You can use any second Sustain here depending on the content.
            </li>
          </ul>
        </>
      )}
      {name === 'littara' && (
        <>
          <h6>DPS/Amplifier (pick 2 characters):</h6>
          <ul>
            <li>
              <GFLCharacter
                slug="tololo"
                mode="inline"
                enablePopover
                showLabel
              />
            </li>
            <li>
              <GFLCharacter
                slug="ullrid"
                mode="inline"
                enablePopover
                showLabel
              />
            </li>
            <li>
              <GFLCharacter
                slug="mosin-nagant"
                mode="inline"
                enablePopover
                showLabel
              />
            </li>
            <li>
              <GFLCharacter
                slug="nagant"
                mode="inline"
                enablePopover
                showLabel
              />
            </li>
            <li>
              <GFLCharacter
                slug="nemesis"
                mode="inline"
                enablePopover
                showLabel
              />
            </li>
            <li>
              <GFLCharacter
                slug="sabrina"
                mode="inline"
                enablePopover
                showLabel
              />{' '}
              - When you can perform Counters on enemies.
            </li>
            <li>
              <GFLCharacter
                slug="groza"
                mode="inline"
                enablePopover
                showLabel
              />{' '}
              - When you can perform Counters on enemies.
            </li>
          </ul>
          <h6>Sustain (pick 1 character):</h6>
          <ul>
            <li>
              <GFLCharacter
                slug="suomi"
                mode="inline"
                enablePopover
                showLabel
              />
            </li>
            <li>
              <GFLCharacter
                slug="ksenia"
                mode="inline"
                enablePopover
                showLabel
              />
            </li>
            <li>
              <GFLCharacter
                slug="cheeta"
                mode="inline"
                enablePopover
                showLabel
              />
            </li>
            <li>
              <GFLCharacter
                slug="colphne"
                mode="inline"
                enablePopover
                showLabel
              />
            </li>
          </ul>
        </>
      )}
      {name === 'lotta' && (
        <>
          <h6>DPS/Amplifier (pick 2 characters):</h6>
          <ul>
            <li>
              <GFLCharacter
                slug="tololo"
                mode="inline"
                enablePopover
                showLabel
              />
            </li>
            <li>
              <GFLCharacter
                slug="ullrid"
                mode="inline"
                enablePopover
                showLabel
              />
            </li>
            <li>
              <GFLCharacter
                slug="mosin-nagant"
                mode="inline"
                enablePopover
                showLabel
              />
            </li>
            <li>
              <GFLCharacter
                slug="nagant"
                mode="inline"
                enablePopover
                showLabel
              />
            </li>
            <li>
              <GFLCharacter
                slug="nemesis"
                mode="inline"
                enablePopover
                showLabel
              />
            </li>
            <li>
              <GFLCharacter
                slug="sabrina"
                mode="inline"
                enablePopover
                showLabel
              />{' '}
              - When you can perform Counters on enemies.
            </li>
            <li>
              <GFLCharacter
                slug="groza"
                mode="inline"
                enablePopover
                showLabel
              />{' '}
              - When you can perform Counters on enemies.
            </li>
          </ul>
          <h6>Sustain (pick 1 character):</h6>
          <ul>
            <li>
              <GFLCharacter
                slug="suomi"
                mode="inline"
                enablePopover
                showLabel
              />
            </li>
            <li>
              <GFLCharacter
                slug="ksenia"
                mode="inline"
                enablePopover
                showLabel
              />
            </li>
            <li>
              <GFLCharacter
                slug="cheeta"
                mode="inline"
                enablePopover
                showLabel
              />
            </li>
            <li>
              <GFLCharacter
                slug="colphne"
                mode="inline"
                enablePopover
                showLabel
              />
            </li>
          </ul>
        </>
      )}
      {name === 'krolik' && (
        <>
          <h6>DPS/Amplifier (pick 1 character):</h6>
          <ul>
            <li>
              <GFLCharacter
                slug="qiongjiu"
                mode="inline"
                enablePopover
                showLabel
              />{' '}
              - Best option
            </li>
            <li>
              <GFLCharacter
                slug="nagant"
                mode="inline"
                enablePopover
                showLabel
              />
            </li>
            <li>
              <GFLCharacter
                slug="sabrina"
                mode="inline"
                enablePopover
                showLabel
              />{' '}
              - situational
            </li>
          </ul>
          <h6>Sustain/Tank (pick 1 character):</h6>
          <ul>
            <li>
              <GFLCharacter
                slug="sabrina"
                mode="inline"
                enablePopover
                showLabel
              />
            </li>
            <li>
              <GFLCharacter
                slug="suomi"
                mode="inline"
                enablePopover
                showLabel
              />
            </li>
            <li>
              <GFLCharacter
                slug="cheeta"
                mode="inline"
                enablePopover
                showLabel
              />
            </li>
            <li>
              <GFLCharacter
                slug="ksenia"
                mode="inline"
                enablePopover
                showLabel
              />
            </li>
            <li>
              <GFLCharacter
                slug="groza"
                mode="inline"
                enablePopover
                showLabel
              />
            </li>
            <li>
              <GFLCharacter
                slug="colphne"
                mode="inline"
                enablePopover
                showLabel
              />
            </li>
          </ul>
          <h6>DPS (pick 1 character):</h6>
          <ul>
            <li>
              <GFLCharacter
                slug="sharkry"
                mode="inline"
                enablePopover
                showLabel
              />
            </li>
            <li>
              <GFLCharacter
                slug="ullrid"
                mode="inline"
                enablePopover
                showLabel
              />
            </li>
            <li>
              <GFLCharacter
                slug="tololo"
                mode="inline"
                enablePopover
                showLabel
              />
            </li>
            <li>
              <GFLCharacter
                slug="mosin-nagant"
                mode="inline"
                enablePopover
                showLabel
              />
            </li>
            <li>
              You can use any second Sustain here depending on the content.
            </li>
          </ul>
        </>
      )}
      {name === 'groza' && (
        <>
          <h6>DPS/Amplifier (pick 1 character):</h6>
          <ul>
            <li>
              <GFLCharacter
                slug="qiongjiu"
                mode="inline"
                enablePopover
                showLabel
              />{' '}
              - Best option
            </li>
            <li>
              <GFLCharacter
                slug="nagant"
                mode="inline"
                enablePopover
                showLabel
              />
            </li>
            <li>
              <GFLCharacter
                slug="cheeta"
                mode="inline"
                enablePopover
                showLabel
              />
            </li>
          </ul>
          <h6>Sustain/DPS (pick 1 character):</h6>
          <ul>
            <li>
              <GFLCharacter
                slug="suomi"
                mode="inline"
                enablePopover
                showLabel
              />
            </li>
            <li>
              <GFLCharacter
                slug="cheeta"
                mode="inline"
                enablePopover
                showLabel
              />
            </li>
            <li>
              <GFLCharacter
                slug="ksenia"
                mode="inline"
                enablePopover
                showLabel
              />
            </li>
            <li>
              <GFLCharacter
                slug="colphne"
                mode="inline"
                enablePopover
                showLabel
              />
            </li>
            <li>
              <GFLCharacter
                slug="sharkry"
                mode="inline"
                enablePopover
                showLabel
              />
            </li>
            <li>
              <GFLCharacter
                slug="tololo"
                mode="inline"
                enablePopover
                showLabel
              />
            </li>
            <li>
              <GFLCharacter
                slug="ullrid"
                mode="inline"
                enablePopover
                showLabel
              />
            </li>
            <li>
              <GFLCharacter
                slug="mosin-nagant"
                mode="inline"
                enablePopover
                showLabel
              />
            </li>
          </ul>
          <h6>DPS (pick 1 character):</h6>
          <ul>
            <li>
              <GFLCharacter
                slug="sharkry"
                mode="inline"
                enablePopover
                showLabel
              />
            </li>
            <li>
              <GFLCharacter
                slug="ullrid"
                mode="inline"
                enablePopover
                showLabel
              />
            </li>
            <li>
              <GFLCharacter
                slug="tololo"
                mode="inline"
                enablePopover
                showLabel
              />
            </li>
            <li>
              <GFLCharacter
                slug="mosin-nagant"
                mode="inline"
                enablePopover
                showLabel
              />
            </li>
            <li>
              You can use any second Sustain here depending on the content.
            </li>
          </ul>
        </>
      )}
      {name === 'nagant' && (
        <>
          <h6>DPS/Amplifier (pick 2 characters):</h6>
          <ul>
            <li>
              <GFLCharacter
                slug="tololo"
                mode="inline"
                enablePopover
                showLabel
              />
            </li>
            <li>
              <GFLCharacter
                slug="ullrid"
                mode="inline"
                enablePopover
                showLabel
              />
            </li>
            <li>
              <GFLCharacter
                slug="mosin-nagant"
                mode="inline"
                enablePopover
                showLabel
              />
            </li>
            <li>
              <GFLCharacter
                slug="sharkry"
                mode="inline"
                enablePopover
                showLabel
              />{' '}
              - Best if you have V3 Qiongjiu
            </li>
            <li>
              <GFLCharacter
                slug="nemesis"
                mode="inline"
                enablePopover
                showLabel
              />
            </li>
            <li>
              <GFLCharacter
                slug="sabrina"
                mode="inline"
                enablePopover
                showLabel
              />{' '}
              - When you can perform Counters on enemies.
            </li>
            <li>
              <GFLCharacter
                slug="groza"
                mode="inline"
                enablePopover
                showLabel
              />{' '}
              - When you can perform Counters on enemies.
            </li>
          </ul>
          <h6>Sustain (pick 1 character):</h6>
          <ul>
            <li>
              <GFLCharacter
                slug="suomi"
                mode="inline"
                enablePopover
                showLabel
              />
            </li>
            <li>
              <GFLCharacter
                slug="ksenia"
                mode="inline"
                enablePopover
                showLabel
              />
            </li>
            <li>
              <GFLCharacter
                slug="cheeta"
                mode="inline"
                enablePopover
                showLabel
              />
            </li>
            <li>
              <GFLCharacter
                slug="colphne"
                mode="inline"
                enablePopover
                showLabel
              />
            </li>
          </ul>
        </>
      )}
      {name === 'nemesis' && (
        <>
          <h6>DPS/Amplifier (pick 1 character):</h6>
          <ul>
            <li>
              <GFLCharacter
                slug="qiongjiu"
                mode="inline"
                enablePopover
                showLabel
              />{' '}
              - Best option
            </li>
            <li>
              <GFLCharacter
                slug="nagant"
                mode="inline"
                enablePopover
                showLabel
              />
            </li>
            <li>
              <GFLCharacter
                slug="sabrina"
                mode="inline"
                enablePopover
                showLabel
              />{' '}
              - situational
            </li>
          </ul>
          <h6>Sustain/Tank (pick 1 character):</h6>
          <ul>
            <li>
              <GFLCharacter
                slug="sabrina"
                mode="inline"
                enablePopover
                showLabel
              />
            </li>
            <li>
              <GFLCharacter
                slug="suomi"
                mode="inline"
                enablePopover
                showLabel
              />
            </li>
            <li>
              <GFLCharacter
                slug="cheeta"
                mode="inline"
                enablePopover
                showLabel
              />
            </li>
            <li>
              <GFLCharacter
                slug="ksenia"
                mode="inline"
                enablePopover
                showLabel
              />
            </li>
            <li>
              <GFLCharacter
                slug="groza"
                mode="inline"
                enablePopover
                showLabel
              />
            </li>
            <li>
              <GFLCharacter
                slug="colphne"
                mode="inline"
                enablePopover
                showLabel
              />
            </li>
          </ul>
          <h6>DPS (pick 1 character):</h6>
          <ul>
            <li>
              <GFLCharacter
                slug="sharkry"
                mode="inline"
                enablePopover
                showLabel
              />
            </li>
            <li>
              <GFLCharacter
                slug="ullrid"
                mode="inline"
                enablePopover
                showLabel
              />
            </li>
            <li>
              <GFLCharacter
                slug="tololo"
                mode="inline"
                enablePopover
                showLabel
              />
            </li>
            <li>
              <GFLCharacter
                slug="mosin-nagant"
                mode="inline"
                enablePopover
                showLabel
              />
            </li>
            <li>
              You can use any second Sustain here depending on the content.
            </li>
          </ul>
        </>
      )}
      {name === 'sharkry' && (
        <>
          <h6>DPS/Amplifier (pick 1 character):</h6>
          <ul>
            <li>
              <GFLCharacter
                slug="qiongjiu"
                mode="inline"
                enablePopover
                showLabel
              />{' '}
              - Best option
            </li>
            <li>
              <GFLCharacter
                slug="nagant"
                mode="inline"
                enablePopover
                showLabel
              />
            </li>
            <li>
              <GFLCharacter
                slug="sabrina"
                mode="inline"
                enablePopover
                showLabel
              />{' '}
              - situational
            </li>
          </ul>
          <h6>Sustain/Tank (pick 1 character):</h6>
          <ul>
            <li>
              <GFLCharacter
                slug="sabrina"
                mode="inline"
                enablePopover
                showLabel
              />
            </li>
            <li>
              <GFLCharacter
                slug="suomi"
                mode="inline"
                enablePopover
                showLabel
              />
            </li>
            <li>
              <GFLCharacter
                slug="cheeta"
                mode="inline"
                enablePopover
                showLabel
              />
            </li>
            <li>
              <GFLCharacter
                slug="ksenia"
                mode="inline"
                enablePopover
                showLabel
              />
            </li>
            <li>
              <GFLCharacter
                slug="groza"
                mode="inline"
                enablePopover
                showLabel
              />
            </li>
            <li>
              <GFLCharacter
                slug="colphne"
                mode="inline"
                enablePopover
                showLabel
              />
            </li>
          </ul>
          <h6>DPS (pick 1 character):</h6>
          <ul>
            <li>
              <GFLCharacter
                slug="ullrid"
                mode="inline"
                enablePopover
                showLabel
              />
            </li>
            <li>
              <GFLCharacter
                slug="tololo"
                mode="inline"
                enablePopover
                showLabel
              />
            </li>
            <li>
              <GFLCharacter
                slug="mosin-nagant"
                mode="inline"
                enablePopover
                showLabel
              />
            </li>
            <li>
              You can use any second Sustain here depending on the content.
            </li>
          </ul>
        </>
      )}
    </>
  );
};
