import React from 'react';
import { GFLWeaponDB } from '../components/exilium-weapon-db';

interface IProps {
  name: string;
}

export const GFLProfileWeapons: React.FC<IProps> = ({ name }) => {
  return (
    <>
      <p>
        Weapons are listed in priority order, but keep in mind that the{' '}
        <strong>Signature Weapon is not a must have</strong> - the Battle Pass
        Weapons or Standard Weapons will work well enough. Only Weapons
        available in Global are included.
      </p>
      {/* SSR */}
      {name === 'qiongjiu' && (
        <>
          <ul>
            <li>
              <GFLWeaponDB name="Golden Melody" mode="inline" /> - BIS
            </li>
            <li>
              <GFLWeaponDB name="Planeta" mode="inline" /> - Good alternative
            </li>
            <li>
              <GFLWeaponDB name="Guerno" mode="inline" /> - Good alternative
            </li>
            <li>
              <GFLWeaponDB name="QBZ-191" mode="inline" /> - SR option
            </li>
            <li>Use any other SR Weapon you have if you lack above options.</li>
          </ul>
        </>
      )}
      {name === 'tololo' && (
        <>
          <ul>
            <li>
              <GFLWeaponDB name="Planeta" mode="inline" /> - BIS
            </li>
            <li>
              <GFLWeaponDB name="Golden Melody" mode="inline" /> - Good
              alternative
            </li>
            <li>
              <GFLWeaponDB name="Guerno" mode="inline" /> - Good alternative
            </li>
            <li>
              <GFLWeaponDB name="Model Alpha" mode="inline" /> - SR option
            </li>
            <li>Use any other SR Weapon you have if you lack above options.</li>
          </ul>
        </>
      )}
      {name === 'sabrina' && (
        <>
          <ul>
            <li>
              <GFLWeaponDB name="Mezzaluna" mode="inline" /> - BIS
            </li>
            <li>
              <GFLWeaponDB name="Heart Seeker" mode="inline" /> - Good
              alternative
            </li>
            <li>
              <GFLWeaponDB name="Expeditionary Pigeon" mode="inline" /> - Good
              alternative
            </li>
            <li>
              <GFLWeaponDB name="M1 Super 90" mode="inline" /> - Good
              alternative
            </li>
            <li>
              <GFLWeaponDB name="Sportivo Calibro 12" mode="inline" /> - SR
              option
            </li>
          </ul>
        </>
      )}
      {name === 'vepley' && (
        <>
          <ul>
            <li>
              <GFLWeaponDB name="M1 Super 90" mode="inline" /> - BIS
            </li>
            <li>
              <GFLWeaponDB name="Heart Seeker" mode="inline" /> - Good
              alternative
            </li>
            <li>
              <GFLWeaponDB name="Expeditionary Pigeon" mode="inline" /> - Good
              alternative
            </li>
            <li>
              <GFLWeaponDB name="Sportivo Calibro 12" mode="inline" /> - SR
              option
            </li>
            <li>Use any other SR Weapon you have if you lack above options.</li>
          </ul>
        </>
      )}
      {name === 'peritya' && (
        <>
          <ul>
            <li>
              <GFLWeaponDB name="Optical Illusion" mode="inline" /> - BIS
            </li>
            <li>
              <GFLWeaponDB name="Mjölnir" mode="inline" /> - Good alternative
            </li>
            <li>
              <GFLWeaponDB name="Pecheneg-SP" mode="inline" /> - SR option
            </li>
            <li>Use any other SR Weapon you have if you lack above options.</li>
          </ul>
        </>
      )}
      {name === 'mosin-nagant' && (
        <>
          <ul>
            <li>
              <GFLWeaponDB name="Samosek" mode="inline" /> - BIS
            </li>
            <li>
              <GFLWeaponDB name="Arcana" mode="inline" /> - Good alternative
            </li>
            <li>
              <GFLWeaponDB name="Three-Line Rifle M1891" mode="inline" /> - SR
              option
            </li>
            <li>Use any other SR Weapon you have if you lack above options.</li>
          </ul>
        </>
      )}
      {name === 'suomi' && (
        <>
          <ul>
            <li>
              <GFLWeaponDB name="Unspoken Calling" mode="inline" /> - BIS
            </li>
            <li>
              <GFLWeaponDB name="Classified Manuscript" mode="inline" /> - Good
              alternative
            </li>
            <li>
              <GFLWeaponDB name="Suomi KP/-31" mode="inline" /> - SR option
            </li>
            <li>Use any other SR Weapon you have if you lack above options.</li>
          </ul>
        </>
      )}
      {name === 'ullrid' && (
        <>
          <ul>
            <li>
              <GFLWeaponDB name="Rectrix" mode="inline" /> - BIS
            </li>
            <li>
              <GFLWeaponDB name="Crowned Jackelope" mode="inline" /> - Good
              alternative
            </li>
            <li>
              <GFLWeaponDB name="Pluma Edge" mode="inline" /> - SR option
            </li>
            <li>Use any other SR Weapon you have if you lack above options.</li>
          </ul>
        </>
      )}
      {/* SR */}
      {name === 'groza' && (
        <>
          <ul>
            <li>
              <GFLWeaponDB name="Guerno" mode="inline" /> - BIS
            </li>
            <li>
              <GFLWeaponDB name="Golden Melody" mode="inline" /> - Good
              alternative
            </li>
            <li>
              <GFLWeaponDB name="Planeta" mode="inline" /> - Good alternative
            </li>
            <li>
              <GFLWeaponDB name="OTs-14" mode="inline" /> - SR option
            </li>
            <li>Use any other SR Weapon you have if you lack above options.</li>
          </ul>
        </>
      )}
      {name === 'sharkry' && (
        <>
          <ul>
            <li>
              <GFLWeaponDB name="Planeta" mode="inline" /> - BIS
            </li>
            <li>
              <GFLWeaponDB name="Golden Melody" mode="inline" /> - Good
              alternative
            </li>
            <li>
              <GFLWeaponDB name="Guerno" mode="inline" /> - Good alternative
            </li>
            <li>
              <GFLWeaponDB name="Robinson Modular Rifle" mode="inline" /> - SR
              option
            </li>
            <li>Use any other SR Weapon you have if you lack above options.</li>
          </ul>
        </>
      )}
      {name === 'littara' && (
        <>
          <ul>
            <li>
              <GFLWeaponDB name="Optical Illusion" mode="inline" /> - BIS
            </li>
            <li>
              <GFLWeaponDB name="Mjölnir" mode="inline" /> - Good alternative
            </li>
            <li>
              <GFLWeaponDB name="Pecheneg-SP" mode="inline" /> - SR option
            </li>
          </ul>
        </>
      )}
      {name === 'lotta' && (
        <>
          <ul>
            <li>
              <GFLWeaponDB name="M1 Super 90" mode="inline" /> - BIS
            </li>
            <li>
              <GFLWeaponDB name="Heart Seeker" mode="inline" /> - Good
              alternative
            </li>
            <li>
              <GFLWeaponDB name="Expeditionary Pigeon" mode="inline" /> - Good
              alternative
            </li>
            <li>
              <GFLWeaponDB name="Sportivo Calibro 12" mode="inline" /> - SR
              option
            </li>
          </ul>
        </>
      )}
      {name === 'cheeta' && (
        <>
          <ul>
            <li>
              <GFLWeaponDB name="Classified Manuscript" mode="inline" /> - BIS
            </li>
            <li>
              <GFLWeaponDB name="MP7H1" mode="inline" /> - SR option
            </li>
          </ul>
        </>
      )}
      {name === 'nemesis' && (
        <>
          <ul>
            <li>
              <GFLWeaponDB name="Arcana" mode="inline" /> - BIS
            </li>
            <li>
              <GFLWeaponDB name="Samosek" mode="inline" /> - Good alternative
            </li>
            <li>
              <GFLWeaponDB name=".50 Nemesis" mode="inline" /> - SR option
            </li>
          </ul>
        </>
      )}
      {name === 'krolik' && (
        <>
          <ul>
            <li>
              <GFLWeaponDB name="Crowned Jackelope" mode="inline" /> - BIS
            </li>
            <li>
              <GFLWeaponDB name="Rectrix" mode="inline" /> - Good alternative
            </li>
            <li>
              <GFLWeaponDB name="Hare" mode="inline" /> - SR option
            </li>
            <li>Use any other SR Weapon you have if you lack above options.</li>
          </ul>
        </>
      )}
      {name === 'colphne' && (
        <>
          <ul>
            <li>
              <GFLWeaponDB name="Papa-Figo" mode="inline" /> - BIS
            </li>
            <li>
              <GFLWeaponDB name=".380 Curva" mode="inline" /> - Good alternative
            </li>
            <li>Use any other SR Weapon you have if you lack above options.</li>
          </ul>
        </>
      )}
      {name === 'ksenia' && (
        <>
          <ul>
            <li>
              <GFLWeaponDB name="Papa-Figo" mode="inline" /> - BIS
            </li>
            <li>
              <GFLWeaponDB name="Stechkin" mode="inline" /> - Good alternative
            </li>
            <li>Use any other SR Weapon you have if you lack above options.</li>
          </ul>
        </>
      )}
      {name === 'nagant' && (
        <>
          <ul>
            <li>
              <GFLWeaponDB name="Papa-Figo" mode="inline" /> - BIS
            </li>
            <li>
              <GFLWeaponDB name="Nagant M1895" mode="inline" /> - Good
              alternative
            </li>
            <li>Use any other SR Weapon you have if you lack above options.</li>
          </ul>
        </>
      )}
    </>
  );
};
